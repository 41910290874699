import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, ElementType } from 'react'

import { DatoLink } from '@/features/dato-link'
import { colors } from '@/theme/variables'

import { DatoStructuredText } from '../DatoStructuredText/DatoStructuredText'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.DetailBlockFragment | null
  headingLevel?: 2 | 3 | 4 | 5 | 6
  styleVariant?: 'DEFAULT' | 'ALT_1'
}

export const DetailBlock = ({
  data,
  headingLevel = 3,
  styleVariant = 'DEFAULT',
  ...props
}: Props): JSX.Element => {
  const Heading = `h${headingLevel}` as ElementType
  const styles = {
    block: css``,
    heading: css`
      font-size: var(--fs-24);
      font-family: var(--ff-body);
      margin: 0;
      ${styleVariant === 'ALT_1' &&
      css`
        font-family: var(--ff-display);
        font-size: var(--fs-36);
        margin: 0 0 0.5em;
        &:after {
          content: '';
          display: block;
          width: 1em;
          margin-top: 0.5em;
          height: 2px;
          background: ${colors.red};
        }
      `}
    `,
    body: css`
      a {
        display: inline-block;
        color: ${colors.red};
        @media (hover: hover) {
          &:hover {
            color: ${colors.redDark};
          }
        }
      }
    `,
    link: css`
      color: ${colors.red};
      text-transform: uppercase;
      @media (hover: hover) {
        &:hover {
          color: ${colors.redDark};
        }
      }
    `,
  }
  return (
    <div
      {...props}
      css={styles.block}
    >
      <Heading css={styles.heading}>{data?.heading}</Heading>
      <div css={styles.body}>
        <DatoStructuredText data={data?.body} />
      </div>
      {data?.links?.map((link, i) => (
        <DatoLink
          data={link}
          key={i}
          css={styles.link}
          showIcon
          styleVariant="LOWERCASE"
        />
      ))}
    </div>
  )
}

export const DetailBlockFragment = graphql`
  fragment DetailBlock on DatoCmsDetailBlock {
    __typename
    id: originalId
    heading
    body {
      value
    }
    links {
      ... on DatoCmsPageLink {
        ...PageLink
      }
      ... on DatoCmsExternalLink {
        ...ExternalLink
      }
      ... on DatoCmsTertiaryArticleLink {
        ...TertiaryArticleLink
      }
      ... on DatoCmsPdfLink {
        ...PdfLink
      }
      ... on DatoCmsFormLink {
        ...FormLink
      }
    }
  }
`
